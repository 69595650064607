import { render, staticRenderFns } from "./smartDevices.vue?vue&type=template&id=0980bd04&scoped=true"
import script from "./smartDevices.vue?vue&type=script&lang=js"
export * from "./smartDevices.vue?vue&type=script&lang=js"
import style0 from "./smartDevices.vue?vue&type=style&index=0&id=0980bd04&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0980bd04",
  null
  
)

export default component.exports