<template>
  <div class="layout-config h-100 p-10">
    <div class="title">
      <h3>{{ classroomName }}</h3>
      <Button
        type="error"
        to="/config/intelligentControl/smartClassroom"
        class="extra"
        >返回</Button
      >
    </div>
    <Row type="flex" :gutter="10" class="m-t-10">
      <Col span="4">
        <Input
          v-model="param.classroomName"
          placeholder="请输入智能场景名称"
          clearable
        />
      </Col>
      <Col span="4">
        <Button type="primary" @click="getData()">查询</Button>
        <Button type="success" class="m-l-5" @click="goUrl">新增</Button>
      </Col>
      <Col span="16" class="text-r"> </Col>
    </Row>
    <Table
      border
      :columns="cols"
      :data="table.data"
      ref="table"
      :height="table.data && table.data.length > 10 ? 500 : ''"
      class="m-t-10"
    />
  </div>
</template>

<script>
import { getScriptByClassroomId, deleteScript } from "@/libs/api/smartClassroom";
export default {
  data() {
    const _this = this
    return {
      param: {},
      table: {},
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        { title: "智能场景名称", key: "name" },
        { title: "创建时间", key: "createTime" },
        {
          title: "操作",
          width: 120,
          render(h, w) {
            const btns = [];
            btns.push(h('Button', {
              props: { type: 'error', size:"small" },
              on: {
                click() {
                  _this.$Modal.confirm({
                    title: '删除',
                    content: `确定删除当前场景--[${w.row.name}]吗？`,
                    onOk: () => {
                      deleteScript(w.row.id).then(res => _this.getData())
                    }
                  })
                }
              }
            }, "删除"))
            return btns;
          },
        },
      ],
    };
  },
  computed: {
    classroomId() {
      // TODO:
      return 'classroom_1001'
      // return this.$route.query.classroomId || null;
    },
    classroomName() {
      return this.$route.query.classroomName || null;
    },
  },
  methods: {
    // 跳转url
    goUrl() {
      this.$router.push({
        path: "/config/intelligentControl/smartClassroom/smartScenePage",
        query: {
          classroomId: this.classroomId,
          classroomName: this.classroomName
        },
      });
    },
    // 获取数据
    getData() {
      getScriptByClassroomId({ classroom_id: this.classroomId }).then((res) => {
        res.code == "HA0200"
          ? (this.table = res)
          : this.$Message.error(res.msg);
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.layout-config {
  .title {
    position: relative;
    .extra {
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
}
</style>
