import { request } from '@/libs'

/**
 * 智能教室API
 */

// 获取所有教室列表
export function getClassroom(params) {
  return request.get('/yanxue-app/iot/demo/classroom', { params })
}

// 分页获取教室列表
export function getClassroomListByPage(params) {
  return request.get('/yanxue-app/iot/demo/classroom/page', { params })
}

// 获取当前用户所拥有的教室
export function getSmartClassroomByUserId(params) {
  return request.get('/yanxue-app/iot/demo/classroom/user', { params })
}

// 添加
export function addSmartClassroom(params) {
  return request.post('/yanxue-app/iot/demo/classroom', params)
}

// 编辑
// export function updateSmartClassroom(params) {
//   return request.put(`/yanxue-app/iot/demo/classroom?id=${params.classroomId}`, params)
// }

// 删除
export function deleteSmartClassroom(params) {
  return request.delete('/yanxue-app/iot/demo/classroom', { params })
}

// 根据机构ID，获取机构名称
export function getOrgSimpleById(org_id) {
  return request.get('/huiyue-cloud-authserver/v2/simple/org/name', { params: { org_id }})
}

// 获取脚本，根据classroom
export function getScriptByClassroomId(params) {
  return request.get('/yanxue-app/iot/demo/iotscript', { params })
}

// 储存脚本
export function getScript(params) {
  return request.post('/yanxue-app/iot/demo/iotscript', params)
}

// 删除脚本
export function deleteScript(scriptId) {
  return request.delete('/yanxue-app/iot/demo/iotscript', { params: { script_id: scriptId } })
}