<template>
  <div class="devices-warp">
    <RadioGroup v-model="filterBy" type="button" @on-change="getFilterBy">
      <Radio label="">全部</Radio>
      <Radio v-for="(val, key) in deviceType" :key="key" :label="key">{{
        val
      }}</Radio>
    </RadioGroup>
    <Row :gutter="10" class="m-t-20">
      <Col
        span="4"
        v-for="(item, index) in deviceListByType"
        :key="index"
        class="m-b-10"
      >
        <Card
          class="device-warp"
          @mouseover.native="onMouseOver(index)"
          @mouseleave.native="onMouseLeave"
        >
          <div class="device-title">
            <span class="c-h" @click="getDeviceInfo(item)">{{ item.friendly_name }}</span>
            <i-switch
              v-model="deviceIds[item.ieee_address]"
              @on-change="deviceOpenOrClose(item)"
              class="extra"
              v-if="current == index && typeShow.includes(item.model_id)"
            >
              <span slot="open">开</span>
              <span slot="close">关</span>
            </i-switch>
          </div>
          <div class="device-content m-t-10">
            <span>{{ deviceType[item.model_id] }}</span>
            <!--  -->
            <transition name="slide-fade">
              <div v-if="current == index">
                <Button
                  type="default"
                  size="small"
                  @click="renameDevice(item.friendly_name)"
                  >重命名</Button
                >
                <Button
                  type="default"
                  size="small"
                  class="m-l-5"
                  @click="showModal(item)"
                  v-if="typeShow.includes(item.model_id)"
                  >设置</Button
                >
                <Button
                  type="default"
                  size="small"
                  class="m-l-5"
                  @click="delDevice(item.ieee_address)"
                  >删除</Button
                >
              </div>
            </transition>
          </div>
        </Card>
      </Col>
    </Row>
    <GroupModal :modal="modal" @change="groupSetting" />
    <DeviceInfo :value.sync="deviceShow" :params="params" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dict } from "@/config";
import iView from "view-design";
import GroupModal from "../../internetOfThing/modal.vue";
import DeviceInfo from "./deviceInfo.vue";
export default {
  components: { GroupModal, DeviceInfo },
  data() {
    return {
      current: null,
      deviceName: null,
      warningTxt: null,
      iShow: false,
      deviceShow: false,
      params: {},
      deviceIds: {},
      typeShow: ["TS0503B", "TS0505B", "TS0601", "TS0011", "TS0013"],
      filterBy: "",
      deviceType: dict.deviceType,
      modal: {
        show: false,
        group: "",
        typeShow: false,
        disabled: false,
      },
    };
  },
  computed: {
    ...mapState("client", ["client", "devices", "classroomId"]),
    deviceListByType() {
      let list = this.devices;
      if (this.devices.length) {
        if (this.filterBy) {
          list = this.devices.filter((item) => item.model_id == this.filterBy);
        }
      }
      return list;
    },
  },
  methods: {
    // 按类型过滤数据
    getFilterBy(val) {
      this.filterBy = val;
    },
    // 设备开启或关闭
    deviceOpenOrClose(device) {
      const topic = `zigbee/${this.classroomId}/${device.ieee_address}/set`;
      let paramsOn = {
        TS0503B: { state: "ON" },
        TS0505B: { state: "ON" },
        TS0601: { state: "OPEN" },
        TS0011: { state: "ON" },
        TS0013: { state_left: "ON", state_center: "ON", state_right: "ON" },
      };
      let paramsOff = {
        TS0503B: { state: "OFF" },
        TS0505B: { state: "OFF" },
        TS0601: { state: "CLOSE" },
        TS0011: { state: "OFF" },
        TS0013: { state_left: "OFF", state_center: "OFF", state_right: "OFF" },
      };
      this.$store.dispatch("client/onPublish", {
        topic,
        params: this.deviceIds[device.ieee_address]
          ? paramsOn[device.model_id]
          : paramsOff[device.model_id],
      });
    },
    // 打开弹框
    showModal(device) {
      const types = {
        TS0503B: "1",
        TS0505B: "1",
        TS0601: "2",
        TS0011: "3",
        TS0013: "3",
      };
      Object.assign(this.modal, {
        show: true,
        title: device.friendly_name,
        group: device.ieee_address,
        type: types[device.model_id],
        typeShow: true,
        disabled: types[device.model_id] == '3' ? true : false,
        switchType: device.model_id == "TS0013" ? "2" : "1",
      });
    },
    // 设备参数配置
    groupSetting(res) {
      if (Object.keys(res).length == 0) {
        this.$Message.warning("请先选择配置参数！");
        return;
      }
      this.$store.dispatch("client/onPublish", {
        topic: `zigbee/${this.classroomId}/${this.modal.group}/set`,
        params: res,
      });
      Object.assign(this.modal, { show: false });
    },
    // 鼠标移入设备card
    onMouseOver(index) {
      this.current = index;
    },
    // 鼠标移除设备card
    onMouseLeave() {
      this.current = null;
    },
    // 
    getDeviceInfo(device) {
      console.log(device)
      // 获取设备参数配置
      // this.$store.dispatch("client/onPublish", {
      //   topic: `zigbee/${this.classroomId}/${device.ieee_address}/get`,
      //   params: { state: '' }
      // })
      setTimeout(() => {
        this.deviceShow = true
        this.params = device
      }, 1000)
    },
    // 重命名设备
    renameDevice(deviceName) {
      const _this = this;
      this.$Modal.confirm({
        title: "智能设备名称：",
        loading: true,
        render: (h) => {
          return [
            h("Input", {
              props: {
                value: deviceName,
                placeholder: "请输入智能设备名称...",
              },
              on: {
                input: (val) => {
                  this.deviceName = val;
                  this.warningTxt = null;
                },
              },
            }),
            h(
              "p",
              { domProps: { className: "m-t-5 f-12 c-red" } },
              this.warningTxt
            ),
          ];
        },
        onOk: function() {
          if (!_this.deviceName) {
            _this.warningTxt = "*请输入智能设备名称!";
            setTimeout(() => {
              this.buttonLoading = false;
            }, 1000);
            return;
          }
          _this.$store.dispatch("client/renameDevice", {
            from: deviceName,
            to: _this.deviceName,
          });

          _this.deviceName = null;
          iView.Modal.remove();
        },
        onCancel: () => {
          this.deviceName = null;
          this.warningTxt = null;
        },
      });
    },
    // 删除设备
    delDevice(deviceId) {
      const _this = this
      this.$Modal.confirm({
        title: '删除',
        content: `确定删除当前设备吗？`,
        onOk() {
          _this.$store.dispatch("client/delDevice", deviceId);
        }
      })
    },
      
  },
};
</script>

<style lang="less" scoped>
.devices-warp {
  height: calc(100% - 60px);
  overflow-x: hidden;
  overflow-y: scroll;
  .device-warp {
    .device-title {
      font-weight: bold;
      position: relative;
      .extra {
        position: absolute;
        right: 5px;
        top: 0;
      }
    }
    .device-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
    }
  }
}
</style>
