<template>
  <div class="tv-warp">
    <Row>
      <Col span="14">
        <div class="tv-title m-b-10">
          电视ID:
          <Input
            v-model="tvId"
            placeholder="请输入电视ID"
            clearable
            class="w-50"
          />
        </div>
        <Button
          type="dashed"
          long
          icon="md-add"
          @click="isShow = !isShow"
          class="m-t-10 w-60"
          >添加推送画面</Button
        >
        <div class="m-t-20 w-80">
          <h4>投送画面列表</h4>
          <Table
            ref="table"
            :columns="cols"
            :data="playList"
            height="400"
            border
            class="m-t-10"
          />
        </div>
        <div class="m-t-20">
          <Button @click="resetPlay">重置投送画面</Button>
          <Button type="success" @click="playData" class="m-l-5">推送</Button>
          <Button type="primary" @click="playData(1)" class="m-l-5">组播推送</Button>
          <Button type="info" @click="getTv" class="m-l-5">获取电视</Button>
        </div>
      </Col>
      <Col span="10">
        <Form
          ref="form"
          :model="form"
          label-position="left"
          :label-width="100"
          label-colon
          class="form-warp"
          v-if="isShow"
        >
          <h4 class="text-c title p-10 m-b-10">电视投送设置</h4>
          <FormItem label="推送类型">
            <RadioGroup v-model="form.type" type="button" @on-change="getType">
              <Radio v-for="(val, key) in typeName" :key="key" :label="key">{{
                val
              }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="投送画面">
            <GeoFile
              v-model="form.url"
              :text="setFileName()"
              :upload-txt="setFileName(1)"
              :multiple="false"
              :param="{ folderId: 2147, userId: $store.state.user.userId }"
            />
          </FormItem>
          <div class="m-t-10 text-c p-10 footer">
            <Button @click="resetForm" class="m-r-5">重置</Button>
            <Button type="info" @click="addPlayItem">添加</Button>
          </div>
        </Form>
      </Col>
    </Row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { util } from "@/libs";
export default {
  data() {
    const typeName = { img: "图片", video: "视频", swiper: "轮播图" };
    const _this = this;
    return {
      isShow: false,
      type: { 0: "img", 1: "video", 2: "swiper" },
      typeName,
      typeList: [
        { name: "图片", value: "img" },
        { name: "视频", value: "video" },
        { name: "轮播图", value: "swiper" },
      ],
      tvId: "3B17F5E70D2BE0FFD152F323A64B5E03",
      playList: [],
      form: {
        type: "img",
        url: "https://api.xingyaedu.cn/minio-netdisk/file/23590",
      },
      data: {
        action: "play",
        data: [
          {
            url: "https://api.xingyaedu.cn/minio-netdisk/file/23590",
            id: "79001",
            type: "img",
          },
          {
            url:
              "https://vd4.bdstatic.com/mda-pka6a267gt0i4dh1/sc/cae_h264/1699815450460578612/mda-pka6a267gt0i4dh1.mp4?v_from_s=hkapp-haokan-hbe&auth_key=1702017324-0-0-0bcc1049aed1f1c0a886cb518ddd2625&bcevod_channel=searchbox_feed&pd=1&cr=2&cd=0&pt=3&logid=2124496005&vid=12810412515205370822&klogid=2124496005&abtest=114240_2-114675_1",
            id: "3601",
            type: "video",
          },
        ],
      },
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        {
          title: "推送类型",
          width: 100,
          render(h, w) {
            return h("span", typeName[w.row.type]);
          },
        },
        { title: "文件地址", key: "url" },
        {
          title: "操作",
          width: 100,
          render(h, w) {
            return h("Button", {
              props: { type: "error", size: "small", icon: "md-close" },
              on: {
                click() {
                  _this.playList.splice(w.row._index, 1);
                },
              },
            });
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("client", ["client"]),
    classroomId() {
      return "classroom_1001";
      // return this.$route.query.classroomId || null;
    },
    classroomName() {
      return this.$route.query.classroomName || null;
    },
  },
  methods: {
    getType(val) {
      this.form.type = val;
    },
    setFileName(flag) {
      let str = "文件";
      if (this.form.type) {
        str = this.typeName[this.form.type];
      }
      return `${flag ? "上传" : "选择"}${str}`;
    },
    resetForm() {
      if (this.$refs.form) this.$refs.form.resetFields();
      this.form = {
        type: "img",
      };
    },
    addPlayItem() {
      if (!this.form.url) {
        this.$Message.warning("请先选择投送画面");
        return;
      }
      this.playList.push({ id: util.uuid(), ...this.form });
      this.isShow = !this.isShow;
      this.resetForm();
    },
    resetPlay() {
      this.tvId = null;
      this.playList = [];
    },
    playData(isMulticast = 0) {
      // isMulticast 0: 单播， 1: 组播
      if(!isMulticast) {
        if (!this.tvId) {
          this.$Message.warning("请先输入电视Id!");
          return;
        }
      }
      if (!this.playList.length) {
        this.$Message.warning("请先选择投送画面!");
        return;
      }
      let params = {
        action: "play",
        data: this.playList,
      };
      console.log(
        "params:",
        params,
        `wifi/${this.classroomId}/tv/${isMulticast ? 'push' : this.tvId}`
      );
      this.client.publish(
        `wifi/${this.classroomId}/tv/${isMulticast ? 'push' : this.tvId}`,
        JSON.stringify(params),
        { qos: 0 }
      );
    },
    getTv() {
      const params = {
        action: "info",
      };
      const paramsStr = JSON.stringify(params);
      this.client.publish(`wifi/${this.classroomId}/tv`, paramsStr, { qos: 0 });
    },
  },
  mounted() {
    this.playList = this.data.data
  }
};
</script>

<style lang="less" scoped>
@border-color: #dcdee2;
.form-warp {
  border: dashed 1px @border-color;
  border-radius: 10px;
  padding: 10px;
  .title {
    border-bottom: dashed 1px @border-color;
  }
  .footer {
    border-top: dashed 1px @border-color;
  }
}
</style>
