<template>
  <Modal
    v-model="iShow"
    :title="params.friendly_name"
    width="1000"
    footer-hide
    class-name="device-info-warp"
    v-if="modal"
  >
    <div
      class="link-warp"
      :style="{
        background:
          modal.linkquality >= '60'
            ? 'linear-gradient(to right, #11AD6F, #43CE97)'
            : 'linear-gradient(to right, #FF743B, #FEB459)',
      }"
    >
      <div class="link-left">
        <div class="title">连接质量</div>
        <div class="icon text-c"><i class="iconfont icon-lianjie f-24" /></div>
      </div>
      <div class="link-right">{{ modal.linkquality }}</div>
    </div>
    <template v-if="type == 'TS0013'">
      <div class="link-warp">
        <div class="link-left">
          <div class="title">左边开关</div>
        </div>
        <div class="link-right">
          <i-switch />
        </div>
      </div>
    </template>
    <div class="three-off-warp m-t-10" v-if="type == 'TS0013'">
      <div class="left">
        <div class="title">左边开关</div>
        <div class=""></div>
      </div>
      <div class="center">
        
        <div class="title">中间开关</div>
      </div>
      <div class="right">
        <div class="title">右边开关</div>
      </div>
    </div>

    <!--  {{ type }} {{ type == 'TS0013' }} -->
    <div class="three-on-off-warp">
      {{ members[params.friendly_name] }}
    </div>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  model: {
    prop: "value",
  },
  props: {
    value: { type: Boolean, default: false },
    params: { type: Object, default: () => {} },
    isDevice: { type: Boolean, default: true },
  },
  data() {
    return {
      modal: {},
      /**
       * "TS0503B": "灯带",
        "TS0505B": '投光灯',
        "TS0601": '窗帘',
        "TS0011": "单键开关",
        "TS0013": "三键开关",
       */
      typeShow: ["TS0503B", "TS0505B", "TS0601", "TS0011", "TS0013"],
    };
  },
  computed: {
    ...mapState("client", ["client", "members"]),
    iShow: {
      set(val) {
        this.$emit("update:value", val);
        return val;
      },
      get() {
        return this.value;
      },
    },
    type() {
      if (this.isDevice) {
        const { model_id } = this.params;
        return model_id;
      } else {
        return "group";
      }
    },
    color() {
      if (this.members[this.params]) {
      }
    },
  },
  mounted() {
    this.$watch("iShow", (show) => {
      console.log("show", show);
      if (show) {
        this.modal = this.members[this.params.friendly_name];
        console.log("this.members", this.members);
      }
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
/deep/ .device-info-warp {
  .ivu-modal-header {
    border-bottom: none;
  }
  .link-warp {
    width: 160px;
    height: 80px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: solid 1px #efefef;
    // background: linear-gradient(to right, #11AD6F, #43CE97);
    // background: linear-gradient(to right, #FF743B, #FEB459);
    color: #fff;
    padding: 10px;
    &:hover {
      // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    }
    .link-right {
      font-weight: bold;
      font-size: 30px;
      text-align: center;
    }
  }
  .three-off-warp {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
