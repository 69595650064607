<template>
  <div class="layout-config-device h-100 p-10">
    <div class="title">
      <h3>
        <span>{{ classroomName }}</span>
        <Tag
          type="dot"
          :color="classroomState == 'online' ? 'success' : ''"
          class="m-l-10"
          v-if="classroomState"
          >{{ classroomState == "online" ? "在线" : "离线" }}</Tag
        >
        <Button icon="md-settings" class="m-l-5" @click="iSetting = !iSetting"></Button>
        <transition name="fade" v-if="iSetting">
          <span>
            <Button type="info" class="m-l-5" @click="reStart">重启</Button>
            <Button type="primary" class="m-l-5" @click="healthCheck">健康状态检查</Button>

          </span>
        </transition>
      </h3>
      <Button
        type="error"
        to="/config/intelligentControl/smartClassroom"
        class="extra"
        >返回</Button
      >
    </div>
    <Tabs v-model="current" class="tabs-warp" @on-click="getCurrent">
      <TabPane label="设备列表" name="device" class="tab-warp">
        <DevicesItem />
      </TabPane>
      <TabPane label="电视投送" name="tv" class="tab-warp">
        <TvItem />
      </TabPane>
      <TabPane label="群组设置" name="group" class="tab-warp">
        <GroupItem />
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import MqttServer from "@/libs/mqttServer";
import store from "@/modules";
import { mapState } from "vuex";
import SmartScene from "./smartScene.vue";
import DevicesItem from "./components/deviceItem.vue"
import TvItem from "./components/tvItem.vue"
import GroupItem from "./components/groupItem.vue"
export default {
  components: {
    SmartScene,
    DevicesItem,
    TvItem,
    GroupItem
  },
  data() {
    return {
      iSetting: false,
      current: "device",
    };
  },
  computed: {
    ...mapState("client", ["mqttServer", "classroomState", "client"]),
    classroomId() {
      return "classroom_1001";
      // return this.$route.query.classroomId || null;
    },
    classroomName() {
      return this.$route.query.classroomName || null;
    },
  },
  methods: {
    getCurrent() {
      console.log('this.current', this.current)
    },
    // 删除设备
    delDevice(deviceId) {
      this.$store.dispatch('client/delDevice', deviceId)
    },
    // 健康状态检查
    healthCheck() {
      this.$store.dispatch('client/healthCheck')
    },
    // 重启服务
    reStart() {
      this.$store.dispatch('client/reStart')
    },
    getTv() {
      const params = {
        action: 'info'
      }
      const paramsStr = JSON.stringify(params)
      this.client.publish(`wifi/${this.classroomId}/tv`, paramsStr, { qos: 0 })
    },
    createClient() {
      const client = new MqttServer();
      store.commit("client/SET_MQTT_SERVER", client);
      store.dispatch("client/getClientMessage");
      this.client.subscribe(`zigbee/${this.classroomId}/#`);
      this.client.subscribe(`wifi/#`);
    },
  },
  mounted() {
    store.commit("client/SET_CLASSROOM_ID", this.classroomId);
    if (!this.mqttServer) {
      this.createClient();
    } else {
      const classroomId = localStorage.getItem("classroomId");
      console.log("classroomId:", classroomId, this.classroomId);
      if (classroomId != this.classroomId) {
        this.client.unsubscribe(`zigbee/${classroomId}/#`);
        store.commit("client/SET_CLASSROOM_ID", this.classroomId);
        this.client.subscribe(`zigbee/${this.classroomId}/#`);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.layout-config-device {
  .title {
    position: relative;
    .extra {
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
  .tabs-warp {
    position: relative;
    height: calc(100% - 40px);
    /deep/ .ivu-tabs-content {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
